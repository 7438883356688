// import * as Constants from '../constants/appConst';
import httpService from "./http.service";
import * as Constants  from '../Constants/apiConst';

export const chainService = {
    getAllChains,
    addNew,
    update,
    deleteChain
};

async function getAllChains(params) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.CHAINS;
    path += params;
    return await httpService.get(path);
}

async function addNew(chain) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.CHAINS;
    return await httpService.post(path, chain);
}

async function update(chain) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.CHAINS;
    return await httpService.put(path, chain);
}

async function deleteChain(id) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.CHAINS;
    path += `/${id}`
    return await httpService.delete(path);
}