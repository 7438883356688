import React, { useState, useEffect, useContext } from 'react';
import DataGrid, {
    Column,
    Paging,
    Pager,
    FilterRow,
    Editing,
    RequiredRule,
    LoadPanel,
    Scrolling
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { chainService } from '../../services/chain.service';
import toast from '../../components/toast';
import Context from '../../utils/context';
import { Template } from 'devextreme-react/core/template';

const Chains = () => {

    const context = useContext(Context);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        setDataSource(chainsDS());
    }, []);

    function isNotEmpty(value) {
        return value !== undefined && value !== null && value !== '';
    }

    const chainsDS = () => {
        return {
            store: new CustomStore({
                key: 'id',
                load: async (loadOptions) => {
                    let params = '?';
                    [
                        'skip',
                        'take',
                        'requireTotalCount',
                        'requireGroupCount',
                        'sort',
                        'filter',
                        'totalSummary',
                        'group',
                        'groupSummary'
                    ].forEach(function (i) {
                        if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
                    });
                    params = params.slice(0, -1);
                    let result = await chainService.getAllChains(params);
                    return {
                        data: result.data,
                        totalCount: result.totalCount,
                        summary: result.summary,
                        groupCount: result.groupCount
                    };
                },
                insert: async (values) => {
                    if (Object.keys(values).length > 0) {
                        values.chainName = values.chainName.trim();
                        values.connectionString = values.connectionString.trim();
                        await chainService.addNew(values);
                        toast('Chain is created', 'success')
                    }
                },
                update: async (key, values) => {
                    values.id = key;
                    values.chainName = values.chainName.trim();
                    values.connectionString = values.connectionString.trim();
                    if (key > 0) {
                        await chainService.update(values);
                        toast('Chain has been updated', 'success')
                    }
                },
                remove: async (key) => {
                    if (key > 0) {
                        await chainService.deleteChain(key);
                        toast('Chain has been deleted', 'success')
                    }
                }
            }),
        }
    }

    const StorePageLink = (e) => {
        const dataRow = e.row && e.row.data;
        if (!dataRow.id) {
            return '';
        }
      
        return <a href={`/#/stores?chainId=${dataRow.id}`} onClick={() => onStoreClick(dataRow.id)}>Stores</a>;
    }

    const onStoreClick = (chainId) => {
        context.setSelectedChainId(chainId);
    }

    const onRowUpdating = (e) => {
        for (var property in e.oldData) {
            if (!e.newData.hasOwnProperty(property)) {
                // add a non-existing value  
                e.newData[property] = e.oldData[property];
            }
        }
    }

    const onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'title'
        })
    }

    const toolbarItemRender = () => {
        return (
            <div>
                <h2>{"Chains"}</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="container-fluid pt-4">
        <DataGrid
            dataSource={dataSource}
            className={'container-fluid'}
            showBorders={true}
            remoteOperations={true}
            onToolbarPreparing={onToolbarPreparing}
            defaultFocusedRowIndex={0}
            height={'696px'}
            columnAutoWidth={true}
            onRowUpdating={onRowUpdating}
        >
            <LoadPanel enabled={true} shading={true} shadingColor="rgba(0,0,0,0.4)" showPane={true}/>
            <Editing 
                allowAdding={true}
                allowDeleting={true}
                allowUpdating={true}/>
            <Paging defaultPageSize={5} />
            {/* <Pager showInfo={true} allowedPageSizes={[5, 10, 20]} 
                    showNavigationButtons={true} 
                    showPageSizeSelector={true}>
            </Pager> */}
            <Scrolling mode="infinite"/>
            <FilterRow visible={true} />

            <Column dataField={'id'} 
                caption={'ID'} 
                width="8%"
                allowEditing={false}
            />
            <Column
                dataField={'chainName'}
                caption={'Name'}
                width="10%"
            >
                <RequiredRule/>
            </Column>
            <Column
                dataField={'connectionString'}
                caption={'Connection String'}
                width="50%"
            >
                <RequiredRule/>
            </Column>
            <Column
                dataField={'isActive'}
                caption={'Active'}
                allowSorting={false}
                width="10%"
            />
            <Column
                dataField={'created'}
                allowEditing={false}
                caption={'Created at'}
                dataType={'date'}
            />
            <Column 
                cellRender={StorePageLink} 
                caption="Stores" 
                allowEditing={false} 
            />
            <Template name="title" render={toolbarItemRender} /> 
        </DataGrid>
        </div>
    </React.Fragment>
    )
};

export default Chains;