import { CheckBox, DataGrid, DropDownBox, SelectBox } from 'devextreme-react';
import React, { createRef, useContext, useEffect, useState } from 'react'
import { userService } from '../../../services/user.service';
import List from 'devextreme-react/list';
import Context from '../../../utils/context';
import { Tooltip } from 'devextreme-react';

const AccessRights = (props) => {
    const context = useContext(Context);
    const [defaultUserProfile, setDefaultUserProfile] = useState({});
    const [selectedItemKeys, setSelectedItemKeys] = useState([]);
    const [selectAllState, setSelectAllState] = useState(false);

    useEffect(() => {
        if ((context.editUserPopupVisible && context.selectedUserData) || context.createUserPopupVisible) {
            userService.getDefaultUserProfileConfig().then(res => {
                setDefaultUserProfile(res);
                formatInitialSelection(res);
            })
        }
    }, [context.editUserPopupVisible, context.createUserPopupVisible])

    // This hook will emit the data to edit-user popup form
    useEffect(() => {
        var finalData = [];
        selectedItemKeys.forEach(i => {
            if (i.endPoints.length > 0) {
                let defaultAR = defaultUserProfile.accessRights.find(a => a.name === i.name);
                if (i.endPoints.length === defaultAR.endPoints.length) {
                    finalData.push({ name: i.name, endPoints: ['all'] })
                }
                else {
                    finalData.push({ name: i.name, endPoints: i.endPoints })
                }
            }
        })
        props.onUpdate(finalData);

        // handling the check/uncheck state of SelectAll checkbox
        if (finalData && defaultUserProfile && defaultUserProfile.accessRights && finalData.length === defaultUserProfile.accessRights.length && finalData.every(a => a.endPoints.includes("all"))) {
            setSelectAllState(true);
        }
        else {
            setSelectAllState(false);
        }
    }, [selectedItemKeys])

    // Initially selecting the endpoints
    const formatInitialSelection = (defaultData) => {
        let formatted = []
        let userProfileAccessRights = context.selectedUserData?.userProfile?.accessRights;
        defaultData.accessRights.forEach(a => {
            let existing = userProfileAccessRights && userProfileAccessRights.length > 0 ? userProfileAccessRights.find(b => b.name === a.name) : null;
            if (existing) {
                if (existing.endPoints && existing.endPoints.length > 0) {
                    if (existing.endPoints.includes('all')) {
                        formatted.push({ name: a.name, endPoints: a.endPoints })
                    }
                    else {
                        formatted.push({ name: a.name, endPoints: existing.endPoints })
                    }
                }
                else {
                    formatted.push({ name: a.name, endPoints: existing.endPoints })
                }
            }
            else {
                formatted.push({ name: a.name, endPoints: [] })
            }
        });
        setSelectedItemKeys(formatted);
    }

    const onSelectionChanged = (e, s) => {
        let existing = [...selectedItemKeys];
        let obj = {};

        let selected = existing.find(a => a.name === s.name);

        // updating existing access rights
        if (selected) {
            if (e.addedItems && e.addedItems.length > 0) {
                selected.endPoints = [...selected.endPoints, ...e.addedItems];
            }

            else if (e.removedItems && e.removedItems.length > 0)
                selected.endPoints = selected.endPoints.filter(a => !e.removedItems.includes(a));
        }

        // inserting new access right object
        else {
            obj = { "name": s.name, "endPoints": e.addedItems };
            existing.push(obj);
        }
        setSelectedItemKeys(existing);
    }

    const selectAllAccessRights = (e) => {
        if (e.value) {
            setSelectedItemKeys(JSON.parse(JSON.stringify(defaultUserProfile.accessRights)));
            setSelectAllState(true);
        }
        else {
            setSelectedItemKeys([]);
            setSelectAllState(false);
        }
    }

    const syncDataGridSelection = (e) => {
        setSelectedItemKeys(e.value || []);
    }

    const renderContentTooltip = () => {
        return (
            <div>Grant access to the specific APIs in PublicAPI or "Select All" to grant full access.</div>
        );
    }

    return (
        <React.Fragment>
            <div id="access-rights-container">
                
                <div className="dx-field ">
                    <div id="productNoGeneration1" className="align-items-center ml-1 dx-icon-info img-info-icon"></div>
                    <CheckBox text="Select All" 
                        value={selectAllState}
                        onValueChanged={selectAllAccessRights}
                        className="ml-auto">
                    </CheckBox>
                    <Tooltip
                            target="#productNoGeneration1"
                            showEvent="mouseenter"
                            hideEvent="mouseleave"
                            position="bottom"
                            closeOnOutsideClick={false}
                            contentRender={renderContentTooltip}
                        />
                </div>
                
                {
                    defaultUserProfile && defaultUserProfile.accessRights && defaultUserProfile.accessRights.map((a, i) => {
                        return (
                            <React.Fragment>
                                <div className="d-flex">
                                    <div style={{ margin: "15px" }} className="pr-5 access-right-label">{a.name.replace(/([A-Z])/g, ' $1').trim()}</div>
                                        <DropDownBox
                                            value={selectedItemKeys[i]?.endPoints}
                                            width={"100%"}
                                            placeholder="No access rights given yet. Select here.."
                                            showClearButton={false}
                                            dataSource={selectedItemKeys[i]?.endPoints}
                                            onValueChanged={syncDataGridSelection}
                                        >
                                            <List
                                                dataSource={a.endPoints}
                                                selectionMode="all"
                                                selectedItemKeys={selectedItemKeys[i]?.endPoints}
                                                selectAllMode={'page'}
                                                showSelectionControls={true}
                                                onSelectionChanged={(e) => onSelectionChanged(e, a)}
                                            />
                                        </DropDownBox>
                                </div>
                            </React.Fragment>
                        )
                    })
                }
            </div>

        </React.Fragment>
    )
}

export default AccessRights;