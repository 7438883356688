import React, { useRef, useState, useEffect, useContext } from 'react';
import DataGrid, {
    Column,
    FilterRow,
    Paging,
    Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import CustomStore from 'devextreme/data/custom_store';
import { userService } from '../../services/user.service';
import Context from '../../utils/context';
import DataSource from 'devextreme/data/data_source';
var dropDownOptions = { width: 400 };
var apiCalled = 0;

const PosEmployeesDropDown = (props) => {
    const context = useContext(Context);
    const dropDownBoxRef = useRef();
    const [currentEmployeeID, setCurrentEmployeeID] = useState(props.selectedEmployeeID);
    const [currentEmployeeObj, setCurrentEmployeeObj] = useState(props.employeeObj);

    // function to flatten the nested array (recursively)
    function flatten(data, outputArray) {
        data.forEach((element) => {
            if (Array.isArray(element)) {
                flatten(element, outputArray);
            } else {
                outputArray.push(element);
            }
        });
    }

    const isNotEmpty = (value, i) => {
        let flattened = [];
        if (value && value.length > 0) flatten(value, flattened);

        // validating if filter query contains anything which is null
        if (i === "filter" && value !== undefined && value !== null && value !== '' && flattened.findIndex(i => i == null) > -1) return false;
        return value !== undefined && value !== null && value !== '';
    }

    useEffect(() => {
        // condition to restrict API call in case there is no change in employee dropdown value
        if ((props.employeeObj) && (props.employeeObj.employeeNumber === currentEmployeeID)) return;

        if (((context.editUserPopupVisible && context.selectedUserData) || context.createUserPopupVisible) &&
            (currentEmployeeID && ((!currentEmployeeObj) || (currentEmployeeObj.employeeNumber == currentEmployeeID)))) {
            
            props.setLoading(true);
            // fetching employee object for selected posEmployeeID
            if (apiCalled === 0) {
                apiCalled = 1;
                userService.getPosEmployee(props.selectedChainId, props.selectedEmployeeID).then(res => {
                    setCurrentEmployeeObj(res);
                    props.onEmployeeSelect({ posEmployeeID: currentEmployeeID, employeeObj: res });
                    props.setLoading(false);
                    apiCalled = 0;
                })
            }
        }
        else {
            setCurrentEmployeeObj({})
        }

    }, [currentEmployeeID])

    const posEmployeeDS = () => {
        return new DataSource({
            store: new CustomStore({
                key: 'employeeNumber',
                load: async (loadOptions) => {
                    let params = '?';
                    [
                        'skip',
                        'take',
                        'requireTotalCount',
                        'requireGroupCount',
                        'sort',
                        'filter',
                        'totalSummary',
                        'group',
                        'groupSummary'
                    ].forEach(function (i) {
                        if (i in loadOptions && isNotEmpty(loadOptions[i], i)) {
                            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                        }
                    });

                    params += `chainId=${context.selectedChainId}`
                    let result = await userService.getPosEmployees(params);
                    return {
                        data: result.data,
                        totalCount: result.totalCount,
                        summary: result.summary,
                        groupCount: result.groupCount
                    };
                }
            })
        })
    }

    const [posEmployeesDataSource, setposEmployeesDataSource] = useState(posEmployeeDS());

    const contentRender = () => {
        return (
            <DataGrid
                dataSource={posEmployeesDataSource}
                remoteOperations={true}
                keyExpr="employeeNumber"
                height={250}
                selectedRowKeys={[currentEmployeeID]}
                hoverStateEnabled={true}
                onSelectionChanged={onSelectionChanged}
            >
                <Paging enabled={true} pageSize={10} />
                <Scrolling mode="virtual" />
                <Selection mode="single" />
                <FilterRow visible={true} />
                <Column width="30%" dataField="employeeNumber" />
                <Column width="70%" dataField="fullName" alignment="left" />

            </DataGrid>
        );
    }

    const onSelectionChanged = (selectionChangedArgs) => {
        if (selectionChangedArgs.selectedRowsData.length > 0) {
            if (selectionChangedArgs.selectedRowKeys.length > 0) {
                setCurrentEmployeeID(selectionChangedArgs.selectedRowKeys[0]);
                setCurrentEmployeeObj(selectionChangedArgs.selectedRowsData[0]);
                dropDownBoxRef.current.instance.close();
                props.onEmployeeSelect({ posEmployeeID: selectionChangedArgs.selectedRowKeys[0], employeeObj: selectionChangedArgs.selectedRowsData[0] });
            }
        }
    }

    const syncDropDownSelection = (e) => {
        setCurrentEmployeeID(e.value);
        setCurrentEmployeeObj(null);
        props.onEmployeeSelect({ posEmployeeID: null, employeeObj: null });
    }

    const employeeDisplayExpr = (e) => {
        return (currentEmployeeObj && currentEmployeeObj.employeeNumber) ? currentEmployeeObj.employeeNumber + " - " + currentEmployeeObj.fullName : ""
    };

    return (
        <DropDownBox
            ref={dropDownBoxRef}
            dropDownOptions={dropDownOptions}
            placeholder={"Select an employee.."}
            value={currentEmployeeObj}
            showClearButton={true}
            displayExpr={employeeDisplayExpr}
            onValueChanged={syncDropDownSelection}
            valueExpr="employeeNumber"
            contentRender={contentRender}>
        </DropDownBox>
    );
}

export default PosEmployeesDropDown;
