export const apiConst = {
    ENDPOINT: window.location.origin + "/api/",
    AUTH: 'account/login',
    
    CHAINS: 'chains',
    STORES: 'stores',
    USERS: 'users',
    USERAPPLICATION: 'userapplications',
    APPLICATION: 'applications',
    POSSTORES: 'posstores',
    STORESBYCHAINID: 'storesbychainId',
    SUPPLIERS: 'suppliers',
    USER_PROFILE : 'userprofile',
    SUPPLIERBYID : "suppliers/supplierId"
}