import { localStorageService } from "../services/localstorage.service";

export function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorageService.getAuthData());

    if (user && user.authdata) {
        return { 'Authorization': 'Basic ' + user.authdata, 'Content-Type': 'application/json', };
    } else {
        return {};
    }
}