export const navigation = [
  // {
  //   text: 'Home',
  //   path: '/home',
  //   icon: 'home'
  // },
  // {
  //   text: 'Examples',
  //   icon: 'folder',
  //   items: [
  //     {
  //       text: 'Profile',
  //       path: '/profile'
  //     },
  //     {
  //       text: 'Tasks',
  //       path: '/tasks'
  //     }
  //   ]
  // },
  {
    text: 'Chains',
    path: '/chains',
    icon: 'link'
  },
  {
    text: 'Stores',
    path: '/stores',
    icon: 'mediumiconslayout'
  },
  {
    text: 'Users',
    path: '/users',
    icon: 'group'
  },
];
