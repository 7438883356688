import httpService from "./http.service";
import * as Constants from '../Constants/apiConst';

export const supplierService = {
    getAllSupplier,
    getSupplierByChainID
};

async function getAllSupplier(params) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.SUPPLIERS;
    path += params;
    return await httpService.get(path);
}

async function getSupplierByChainID(chainId, supplierId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.SUPPLIERBYID;
    path += `?chainId=${chainId}&supplierId=${supplierId}`
    return await httpService.get(path);
}


