import httpService from "./http.service";
import * as Constants  from '../Constants/apiConst';

export const applicationService = {
    getAll
};

async function getAll() {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.APPLICATION;
    return await httpService.get(path);
}