import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, { useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import Context from './utils/context';

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function () {
  const screenSizeClass = useScreenSizeClass();
  const [selectedChainId, setSelectedChainId] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState({});
  const [chainStores, setChainStores] = useState(null);
  const [editUserPopupVisible, setEditUserPopupVisible] = useState(false);
  const [createUserPopupVisible, setCreateUserPopupVisible] = useState(false);

  return (
    <Context.Provider value={{
      selectedChainId: selectedChainId,
      setSelectedChainId: (value) => setSelectedChainId(value),

      selectedUserData: selectedUserData,
      setSelectedUserData: (value) => setSelectedUserData(value),

      chainStores: chainStores,
      setChainStores: (value) => setChainStores(value),

      editUserPopupVisible: editUserPopupVisible,
      setEditUserPopupVisible: (value) => setEditUserPopupVisible(value),

      createUserPopupVisible: createUserPopupVisible,
      setCreateUserPopupVisible: (value) => setCreateUserPopupVisible(value),
    }}>
      <Router>
        <AuthProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </AuthProvider>
      </Router>
    </Context.Provider>
  );
}
