import React, { useState, useEffect, useContext, createRef, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import Form, {
    GroupItem,
    EmailRule,
    Item,
    ButtonItem,
    EmptyItem,
    Label
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import Context from '../../utils/context';
import { generatePassword } from '../../utils/password-generator';
import { Accordion, CheckBox, LoadPanel, ScrollView, SelectBox, Tooltip } from 'devextreme-react';
import { TextBox } from 'devextreme-react/text-box';
import { userService } from '../../services/user.service';
import PosEmployeesDropDown from '../../components/dropdowns/pos-employees-dropdown';
import toast from '../../components/toast';
import {
    Validator,
    RequiredRule,
    CustomRule,
} from 'devextreme-react/validator';
import DropDownBox from 'devextreme-react/drop-down-box';
import { userApplicationService } from '../../services/user-application.service';
import DataGrid, { Selection, Paging, FilterRow, Scrolling, Column, Lookup, Button } from 'devextreme-react/data-grid';
import { applicationService } from '../../services/application.service';
import CustomStore from 'devextreme/data/custom_store';
import SupplierDropDown from '../../components/dropdowns/supplier-dropdown';
import AccessRights from './user-profile/access-rights';
import UserConfiguration from "./user-profile/user-configuration";
import { storeDSRaw } from '../../components/data-source/store-data-source';
const EditUser = (props) => {
    const context = useContext(Context);
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        storeID: null,
        posEmployeeID: null,
        userProfile: {
            accessRights: null,
            configuration: null,
            departmentID: null,
            employeeID: null,
            storeID: null,
            supplierID: null,
            supplierName: null,
        }
    });

    const [selectedApplicationBoxValue, setSelectedApplicationBoxValue] = useState(null);
    const [selectedRolesBoxValue, setSelectedRolesBoxValue] = useState(null);
    const [applicationsDataSource, setApplicationsDataSource] = useState(null);
    const [rolesDataSource, setRolesDataSource] = useState(null);
    const [userAppMapping, setUserAppMapping] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [storeDataSource, setStoreDataSource] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [employeeObj, setEmployeeObj] = useState(null);
    const [selectedSupplierObj, setSelectedSupplierObj] = useState(null)
    const [isSupplier, setIsSupplier] = useState(false);

    useEffect(() => {
        if (context.selectedChainId && context.selectedUserData && (context.editUserPopupVisible || context.createUserPopupVisible)) {
            if (context.selectedUserData) {
                setFormData(context.selectedUserData);
            }
            
            if (context.chainStores)
                setStoreDataSource(context.chainStores);
            else
                setStoreDataSource(storeDSRaw(context.selectedChainId));
            
            setApplicationsDataSource(appDS());
            setRolesDataSource(userRolesDS());
            setIsSupplier(context.selectedUserData?.userProfile?.isSupplier ?? false);

            // pre-setting the user's application access selection
            if (context.selectedUserData && context.selectedUserData.id > 0) {
                //User application access
                userApplicationService.getAll(context.selectedUserData.id).then(res => {
                    setSelectedApplicationBoxValue([...new Set(res.filter(s => !s.isDeleted).map(({ applicationID }) => applicationID))])
                })            
            }
            else {
                setSelectedApplicationBoxValue([]);
            }

            // User roles
            if (context.selectedUserData?.userRoles?.length > 0) {
                    setSelectedRolesBoxValue(context.selectedUserData.userRoles.map(({roleID}) => roleID));
            }
            else {
                setSelectedRolesBoxValue([]);
            }
        }

        // resetting employee and supplier objects after popup is closed
        return () => {
            setEmployeeObj(null);
            setSelectedSupplierObj(null);
        }
    }, [context.editUserPopupVisible, context.createUserPopupVisible]);

    useEffect(() => {
        userAppMapping.forEach((m, i) => {
            if (selectedApplicationBoxValue.findIndex(a => a == m.applicationID) > -1) {
                m.isDeleted = false;
            }
            else {
                m.isDeleted = true;
            }
        })
    }, [selectedApplicationBoxValue])

    useEffect(() => {
        userRoles.forEach((m, i) => {
            if (selectedRolesBoxValue.findIndex(a => a == m.roleID) > -1) {
                m.isDeleted = false;
            }
            else {
                m.isDeleted = true;
            }
        })
    }, [selectedRolesBoxValue])

    const updatedFormData = (object) => {
        setFormData({ ...formData, ...object })
    }

    // Method to update fields inside userProfile object
    const updateUserProfile = (userProfile) => {
        formData.userProfile = {
            ...formData.userProfile,
            supplierID: userProfile.supplierID ?? (formData?.userProfile?.supplierID),
            accessRights: userProfile.accessRights != null ? userProfile.accessRights : formData.userProfile?.accessRights ?? [],
            isSupplier: userProfile.isSupplier,
            configuration: userProfile.userConfiguration != null ? userProfile.userConfiguration : formData.userProfile?.configuration,
        }
        setFormData(formData);
    }

    const updateIsSupplier = (e) => {
        formData.userProfile = {
            ...formData.userProfile,
            isSupplier: e.value
        }
        setIsSupplier(e.value);
        setFormData(formData);
    }

    const appDS = () => {
        return new CustomStore({
            loadMode: 'raw',
            key: 'id',
            load: async () => {
                let result = await applicationService.getAll();
                let maps = [];
                result.forEach(app => {
                    maps.push({ userID: (context.selectedUserData?.id > 0 ? context.selectedUserData?.id : 0), applicationID: app.id, isDeleted: false })
                });
                setUserAppMapping(maps);
                return result;
            }
        });
    }

    const userRolesDS = () => {
        return new CustomStore({
            loadMode: 'raw',
            key: 'id',
            load: async () => {
                let result = await userService.getAllRoles();
                let roles = [];
                result.forEach(role => {
                    roles.push({ userID: (context.selectedUserData?.id > 0 ? context.selectedUserData?.id : 0), roleID: role.id, isDeleted: false })
                });
                setUserRoles(roles);
                return result;
            }
        });
    }

    const onSubmit = (user) => {
        try {
            let formInstance = formRef.current.instance;
            var accessRights = null;
            var configuration = null;
            setLoading(true);
            if (user.id && user.id > 0) {
                if (user.userProfile != null || user.supplierID > 0) {
                    user.userProfile.accessRights = user.userProfile.accessRights === null ? user.userProfile.accessRights : user.userProfile.accessRights;
                    user.userProfile.configuration = user.userProfile.configuration === null ? formData.userProfile.configuration : user.userProfile.configuration;
                    user.userProfile.supplierID = user.supplierID === undefined ? user.userProfile.supplierID : user.supplierID;
                    user.userProfile.isSupplier = isSupplier;
                }

                user.userRoles = userRoles;
                userService.update(user).then(res => {
                    userApplicationService.update(userAppMapping).then(res => {
                        toast("user updated", "success");
                        setLoading(false);
                        formInstance.resetValues();
                        props.hidePopup(true);
                        resetForm();
                    })
                })
            }
            else {
                if (user.userProfile != null || user.supplierID > 0) {
                    user.userProfile.accessRights = user.userProfile.accessRights === null ? user.userProfile.accessRights : user.userProfile.accessRights;
                    user.userProfile.configuration = user.userProfile.configuration === null ? formData.userProfile.configuration : user.userProfile.configuration;
                    user.userProfile.isSupplier = isSupplier;
                    user.userProfile.supplierID = user.supplierID === undefined ? user.userProfile.supplierID : user.supplierID;
                }
                user.userRoles = userRoles;
                userService.addNew(user).then(res => {
                    userAppMapping.map(u => u.userID = res.id)
                    userApplicationService.update(userAppMapping).then(res => {
                        toast("user created", "success");
                        setLoading(false);
                        formInstance.resetValues();
                        props.hidePopup(true);
                        resetForm();
                    })
                })
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const formRef = createRef();

    const submitButtonOptions = {
        useSubmitBehavior: true, type: "default", text: "Submit",
        validationGroup: "userData",
        onClick: (e => {
            if (formRef.current.instance.validate().isValid) {
                onSubmit(formData);
            }
        })
    }

    const cancelButtonOptions = {
        text: "Cancel",
        onClick: (e => {
            formRef.current.instance.resetValues();
            resetForm();
            props.hidePopup(false);
        })
    }

    const resetForm = () => {
        // collapsing all accordion items while closing the popup
        accordionRef.current.instance.collapseItem(0);
        accordionRef.current.instance.collapseItem(1);
        context.setEditUserPopupVisible(false);
        context.setCreateUserPopupVisible(false);
        setEmployeeObj(null);
        setSelectedSupplierObj(null);
        setFormData({
            email: '',
            name: '',
            password: '',
            storeID: null,
            posEmployeeID: null,
            userProfile: {
                accessRights: null,
                configuration: null,
                departmentID: null,
                employeeID: null,
                storeID: null,
                supplierID: null,
                supplierName: null,
            },
            supplierID: null
        })
    }

    const syncApplicationDataGridSelection = (e) => {
        setSelectedApplicationBoxValue(e.value || []);
    }

    const syncRoleDataGridSelection = (e) => {
        setSelectedRolesBoxValue(e.value || []);
    }

    const applicationDataGridRender = () => {
        return (
            <DataGrid
                dataSource={applicationsDataSource}
                hoverStateEnabled={true}
                filterRow={{ visible: false }}
                height={"200px"}
                selectedRowKeys={selectedApplicationBoxValue}
                onSelectionChanged={userapp_dataGrid_onSelectionChanged}>
                <Column width="20%" alignment="left" dataField="id" caption="ID">
                </Column>
                <Column width="80%" dataField="applicationName" caption="Application Name">
                </Column>
                <Selection mode="multiple" />
                <Scrolling mode="infinite" />
                <Paging enabled={true} pageSize={1} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    const rolesDataGridRender = () => {
        return (
            <DataGrid
                dataSource={rolesDataSource}
                hoverStateEnabled={true}
                filterRow={{ visible: false }}
                height={"200px"}
                selectedRowKeys={selectedRolesBoxValue}
                onSelectionChanged={userRole_dataGrid_onSelectionChanged}>
                <Column width="20%" alignment="left" dataField="id" caption="ID">
                </Column>
                <Column width="80%" dataField="roleName" caption="Role Name">
                </Column>
                <Selection mode="multiple" />
                <Scrolling mode="infinite" />
                <Paging enabled={true} pageSize={1} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    }

    const userapp_dataGrid_onSelectionChanged = (e) => {
        setSelectedApplicationBoxValue(e.selectedRowKeys.length && e.selectedRowKeys || []);
    }

    const userRole_dataGrid_onSelectionChanged = (e) => {
        setSelectedRolesBoxValue(e.selectedRowKeys.length && e.selectedRowKeys || []);
    }

    const accordionItemRender = (e) => {
        if (e.template === "items[0].template") {
            return (
                    <div className="d-flex">
                    <h6>{"Access rights"}</h6>
                    <label className="pl-3 access-right-accordion-label">(Manage Public APIs access here) </label>
                </div>
            )
        }
        else {
            return (
                <div className="d-flex">
                    <h6>{"User configuration"}</h6>
                    <label className="pl-3 access-right-accordion-label">(Manage User configuration here) </label>
                </div>
            )
        }            
    }

    const accordionRef = useRef();

    const renderContentTooltip = () => {
        return (
            <div>Marking user as test user will cause all <br/> the emails to sent to user's email address instead of supplier.</div>
        );
    }

    const renderContentTooltipIsSupplier = () => {
        return (
            <div>Making it true will cause the products to be created with POS Store ID as null.</div>
        )
    }

    return (
        <React.Fragment>
            <Popup visible={context.editUserPopupVisible || context.createUserPopupVisible}
                title={context.selectedUserData?.id > 0 ? 'Edit User' : 'Create User'}
                showTitle={true} width={1200} height={710}               
                onHiding={() => { formRef.current.instance.resetValues(); resetForm(); props.hidePopup(false) }}>
                <LoadPanel
                    visible={isLoading}
                    shading={true}
                />
                <ScrollView
                    id="scrollview1"
                    scrollByContent={true}
                    bounceEnabled={false}
                    showScrollbar={'always'}
                    scrollByThumb={true}
                >
                    <div className="row">
                        <div className="col-12">
                    <Form formData={formData}
                        ref={formRef}
                        className="edit-user-container"
                        validationGroup="userData"
                        showValidationSummary={false}>

                        <Item itemType="group" colCount={2}>
                            {/* EMAIL */}
                            <Item colSpan={1} dataField="email">
                                <TextBox value={formData?.email}
                                    onValueChanged={(e) => { updatedFormData({ email: e.value.trim() }) }}>
                                    <Validator validationGroup="userData">
                                        <RequiredRule />
                                        <EmailRule />
                                    </Validator>
                                </TextBox>
                                <RequiredRule />
                            </Item>
                            {/* NAME */}
                            <Item dataField="name">
                                <TextBox value={formData?.name}
                                    onValueChanged={(e) => { updatedFormData({ name: e.value.trim() }) }}>
                                    <Validator validationGroup="userData">
                                        <RequiredRule />
                                    </Validator>
                                </TextBox>
                                <RequiredRule />
                            </Item>

                            {/* PASSWORD FIELD */}
                            <Item dataField="password" itemType="group" colCount={3} isRequired={(formData?.id <= 0)} editorOptions={{ disabled: true }}>
                                <Item colSpan={2}>
                                    <TextBox value={formData?.password} readOnly={true}>
                                        <Validator validationGroup="userData">
                                            <CustomRule message={"Password must be generated while adding new user"}
                                                validationCallback={(e) => { return Boolean((formData.id > 0) || formData.password != "") }} />
                                        </Validator>
                                    </TextBox>
                                </Item>
                                <ButtonItem name="password"
                                    location="after"
                                    visible={!isLoading}
                                    buttonOptions={{
                                        icon: 'key',
                                        hint: 'Generate new password',
                                        onClick: () => { updatedFormData({ password: generatePassword() }) }
                                    }}
                                />
                            </Item>

                            {/* POS EMPLOYEE DROPDOWN */}
                            <Item dataField="posEmployeeID">
                                <Label text="POS Employee" />
                                {
                                    context.selectedChainId && 
                                    <PosEmployeesDropDown
                                        selectedEmployeeID={formData.posEmployeeID ?? null}
                                        selectedChainId={context.selectedChainId}
                                        employeeObj={employeeObj}
                                        setLoading={setLoading}
                                        onEmployeeSelect={(e) => { 
                                            updatedFormData({ "posEmployeeID": e.posEmployeeID }); 
                                            setEmployeeObj(e.employeeObj);
                                        }}>
                                    </PosEmployeesDropDown> 
                                }
                            </Item>
                        </Item>

                        <Item itemType="group" colCount={2}>
                            {/* STORE DROPDOWN */}
                            <Item itemType="dxSelectBox" dataField="store" colSpan={1}>
                                <SelectBox
                                    dataSource={storeDataSource}
                                    valueExpr="id"
                                    value={formData?.storeID}
                                    displayExpr="storeName"
                                    onSelectionChanged={(e) => { updatedFormData({ "storeID": e.selectedItem?.id }) }}
                                >
                                    <Validator validationGroup="userData">
                                        <RequiredRule />
                                    </Validator>
                                </SelectBox>
                                <RequiredRule />
                            </Item>

                            {/* User roles dropdown */}
                            <Item dataField="userRoles">
                                <Label text="User roles" />
                                <DropDownBox
                                    value={selectedRolesBoxValue}
                                    valueExpr="id"
                                    displayExpr={(e) => { return e?.roleName }}
                                    placeholder="Select role(s)"
                                    showClearButton={true}
                                    dataSource={rolesDataSource}
                                    onValueChanged={syncRoleDataGridSelection}
                                    contentRender={rolesDataGridRender}
                                />
                                <Tooltip
                                    target="label[for*='userRoles']"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    position="bottom"
                                    closeOnOutsideClick={false}
                                    contentTemplate={function (contentElement) {
                                        contentElement.append("Only applicable in Vareregister");
                                    }}
                                />
                            </Item>

                            <GroupItem colSpan={1} colCount={3}>
                                {/* TEST USER CHECKBOX */}
                                        <Item alignment="right" dataField="isTestUser">  
                                            <Label text="Test user" />
                                    <CheckBox value={formData?.isTestUser ?? false}
                                        defaultValue={false}
                                        onValueChanged={(e) => updatedFormData({ "isTestUser": e.value })}>
                                    </CheckBox>                                    
                                    <Tooltip
                                        target="label[for*='isTestUser']"
                                        position="bottom"
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                        closeOnOutsideClick={false}
                                        contentRender={renderContentTooltip}                                        
                                    >
                                    </Tooltip>
                                </Item>                             

                                {/* USER ACTIVE CHECKBOX */}
                                <Item alignment="right" dataField="isActive">
                                    <CheckBox value={formData?.isActive ?? true}
                                        defaultValue={true}
                                        onValueChanged={(e) => updatedFormData({ "isActive": e.value })}>
                                    </CheckBox>
                                </Item>
                                {/* USER SUPPLIER CHECKBOX */}
                                <Item alignment="right" dataField="isSupplier">
                                    <Label text="Is Supplier" />
                                    <CheckBox value={isSupplier}
                                        defaultValue={false}
                                        onValueChanged={updateIsSupplier}>
                                    </CheckBox>
                                    <Tooltip
                                        target="label[for*='isSupplier']"
                                        position="bottom"
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                        closeOnOutsideClick={false}
                                        contentRender={renderContentTooltipIsSupplier}                                        
                                    >
                                    </Tooltip>                               
                                </Item>
                            </GroupItem>

                            {/* Supplier DROPDOWN */}
                            <Item itemType="group"  colCount={1} dataField={formData.userProfile === null ? 0 : formData.userProfile.supplierID}>
                                <Label text="Supplier" />                                
                                {
                                    context.selectedChainId &&
                                    <SupplierDropDown
                                        selectedSupplierID={formData.userProfile?.supplierID ?? null}
                                        selectedSupplierObj={selectedSupplierObj}
                                        selectedChainId={context.selectedChainId}
                                        setLoading={setLoading}
                                        onSupplierSelect={(e) => { 
                                            updateUserProfile({ "supplierID": e.supplierID }); 
                                            setSelectedSupplierObj(e.supplierObj);
                                        }}>
                                    </SupplierDropDown>
                                }                                                    
                            </Item>      
                        </Item>
                        
                        {/* User application dropdown */}
                        <Item dataField="appAccess">
                            <Label text="Application access" />
                            <DropDownBox
                                value={selectedApplicationBoxValue}
                                valueExpr="id"
                                displayExpr={(e) => { return e?.applicationName }}
                                placeholder="Select a value..."
                                showClearButton={true}
                                dataSource={applicationsDataSource}
                                onValueChanged={syncApplicationDataGridSelection}
                                contentRender={applicationDataGridRender}
                            />
                            <Tooltip
                                target="label[for*='appAccess']"
                                showEvent="mouseenter"
                                hideEvent="mouseleave"
                                position="bottom"
                                closeOnOutsideClick={false}
                                contentTemplate={function (contentElement) {
                                    contentElement.append("Manage user's access to the Touchsoft applications");
                                }}
                            />
                        </Item>

                        {/* ACCESS RIGHTS & USER CONFIGURATION */}
                        <Item itemType="group" colCount={2}>
                            <Accordion
                                defaultSelectedIndex={-1}
                                ref={accordionRef}
                                collapsible={true}
                                // selectedIndex={-1}
                                itemTitleRender={accordionItemRender}>
                                <Item colSpan={2}>
                                    <AccessRights onUpdate={(e) => updateUserProfile({ accessRights: e })} />
                                </Item>
                                <Item colSpan={2}>
                                    <UserConfiguration onUpdate={(e) => updateUserProfile({ userConfiguration: e })} />
                                </Item>
                            </Accordion>
                        </Item>

                        {/* Action buttons */}
                        <Item itemType="group" colCount={2}>
                            <EmptyItem colSpan={1} />
                            <GroupItem cssClass="popup-btn-container" colSpan={1} colCount={2}>
                                <ButtonItem cssClass="pr-0" horizontalAlignment="right" buttonOptions={submitButtonOptions} />
                                <ButtonItem horizontalAlignment="right" buttonOptions={cancelButtonOptions} />
                            </GroupItem>
                        </Item>
                        {/* </ScrollView> */}
                    </Form>
                    </div>
                    </div>
                </ScrollView>
            </Popup >

        </React.Fragment >
    )
}

export default EditUser;