import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, TasksPage, ProfilePage, ChainsPage } from './pages';
import Stores from './pages/stores/stores';
import Users from './pages/users/users';
const routes = [
  {
    path: '/tasks',
    component: TasksPage
  },
  // {
  //   path: '/profile',
  //   component: ProfilePage
  // },
  // {
  //   path: '/home',
  //   component: HomePage
  // },
  {
    path: '/chains',
    component: ChainsPage
  },
  {
    path: '/chains/:chainId/stores',
    component: Stores
  },
  {
    path: '/chains/:chainId/stores/:storeId/users',
    component: Users
  },
  {
    path: '/chains/:chainId/users',
    component: Users
  },
  {
    path: '/users',
    component: Users
  },
  {
    path: '/stores',
    component: Stores
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
