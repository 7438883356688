import React, { useState, useEffect, useContext, createRef } from 'react';
import DataGrid, {
    Column,
    Paging,
    FilterRow,
    Editing,
    RequiredRule,
    Pager,
    LoadPanel,
    Scrolling,
    Lookup
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { chainService } from '../../services/chain.service';
import toast from '../../components/toast';
import Context from '../../utils/context';
import { useHistory } from 'react-router-dom';
import { userService } from '../../services/user.service';
import ChainDropDown from '../../components/dropdowns/chains-dropdown';
import StoreDropDown from '../../components/dropdowns/stores-dropdown';
import { storeDSRaw } from '../../components/data-source/store-data-source';
import 'devextreme-react/text-area';
import EditUser from './edit-user';
import { Template } from 'devextreme-react/core/template';
import { custom } from 'devextreme/ui/dialog';

const Users = (props) => {

    const history = useHistory();
    let routeParams = new URLSearchParams(props.location.search);
    const context = useContext(Context);
    const [storeId, setStoreId] = useState(-1);
    const [chainId, setChainId] = useState(-1);
    const [dataSource, setDataSource] = useState([]);
    const [chainsDataSource, setChainsDataSource] = useState([]);
    const [storeDataSource, setStoreDataSource] = useState([]);
    const userGridRef = createRef();

    useEffect(() => {
        if (routeParams.get("storeId")) {
            setStoreId(Number(routeParams.get("storeId")));
        }
        else {
            setStoreId(null);
        }
        setDataSource(userDS(routeParams.get("storeId"), routeParams.get("chainId")));

        if (routeParams.get("chainId")) {
            storeDSRaw(Number(routeParams.get("chainId"))).store.load().then(res => {
                setStoreDataSource(res)
                context.setChainStores(res);
            })
            setChainId(Number(routeParams.get("chainId")));
            context.setSelectedChainId(Number(routeParams.get("chainId")));
        }
        else {
            setChainId(null);
        }

        setChainsDataSource(chainsDS());
    }, [routeParams.get("storeId"), routeParams.get("chainId")]);

    useEffect(() => {
        return () => {
            context.setSelectedChainId(null);
        }
    }, [])

    const onHideUserPopup = (reloadGrid) => {
        if (reloadGrid) {
            setDataSource(userDS(routeParams.get("storeId"), routeParams.get("chainId")));
        }
    }

    const chainsDS = () => {
        return {
            store: new CustomStore({
                loadMode: 'raw',
                key: 'id',
                load: async () => {
                    let params = '?skip=0&take=9999';
                    let result = await chainService.getAllChains(params);
                    return result?.data;
                },
            }),
        }
    }

    function isNotEmpty(value) {
        return value !== undefined && value !== null && value !== '';
    }

    const userDS = (storeId, chainId) => {
        return {
            store: new CustomStore({
                key: 'id',
                load: async (loadOptions) => {
                    let params = '?';
                    [
                        'skip',
                        'take',
                        'requireTotalCount',
                        'requireGroupCount',
                        'sort',
                        'filter',
                        'totalSummary',
                        'group',
                        'groupSummary'
                    ].forEach(function (i) {
                        if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
                    });

                    let result = [];
                    if (storeId && storeId > 0) {
                        params += `storeId=${storeId}&`;
                    }

                    if (chainId && chainId > 0) {
                        params += `chainId=${chainId}&`;
                    }

                    params = params.slice(0, -1);

                    result = await userService.getAllUsers(params);
                    return {
                        data: result.data,
                        totalCount: result.totalCount,
                        summary: result.summary,
                        groupCount: result.groupCount
                    };
                },
                insert: async (values) => {
                    if (Object.keys(values).length > 0) {
                        values.storeId = storeId;
                        values.name = values.name ? values.name.trim() : null;
                        values.email = values.email ? values.email.trim() : null;
                        await userService.addNew(values);
                        toast('User is created', 'success')
                    }
                },
                update: async (key, values) => {
                    values.id = key;
                    if (key > 0) {
                        await userService.update(values);
                        toast('User has been updated', 'success')
                    }
                },
                remove: async (key) => {
                    if (key > 0) {
                        await userService.deleteUser(key);
                        toast('User has been deleted', 'success')
                    }
                }
            }),
        }
    }

    const onRowUpdating = (e) => {
        for (var property in e.oldData) {
            if (!e.newData.hasOwnProperty(property)) {
                e.newData[property] = e.oldData[property];
            }
        }
    }

    const onChainSelect = (e) => {
        if (e)
            history.push(
                {
                    pathname: `/users`,
                    search: `?chainId=${e}`
                }
            )
        else {
            setStoreId(-1);
            history.push(
                {
                    pathname: `/users`
                }
            )
        }
    }

    const onStoreSelect = (e) => {
        if (e)
            history.push(
                {
                    pathname: `/users`,
                    search: `?chainId=${chainId}&storeId=${e}`
                }
            )
        else
            history.push(
                {
                    pathname: `/users`,
                    search: `?chainId=${chainId}`
                }
            )
    }

    const toOpenCellComponent = (e) => {
        return <a className={'dx-icon-edit dx-link dx-link-icon'} onClick={() => {
            context.setSelectedUserData(e.row.data);
            context.setEditUserPopupVisible(true);
            context.setSelectedChainId(e.row.data.chainID);
        }
        }></a>;
    }

    const onToolbarPreparing = (e) => {
        if (e.toolbarOptions.items && e.toolbarOptions.items.length > 0) {
            e.toolbarOptions.items[0].options.onClick = function () {
                if (chainId && chainId > 0) {
                    context.setSelectedUserData({
                        email: '',
                        name: '',
                        password: '',
                        storeID: storeId && storeId > 0 ? storeId : null,
                        posEmployeeID: null,
                        userProfile: {
                            accessRights: null,
                            configuration: null,
                            departmentID: 0,
                            employeeID: 0,
                            
                            storeID: null,
                            supplierID: null,                            
                        }
                    })
                    context.setCreateUserPopupVisible(true);
                    context.setSelectedChainId(chainId);
                }
                else {
                    const myDialog = custom({
                        showTitle: false,
                        messageHtml: "<h6>Select chain to add new user</h6>",
                        buttons: [{
                            width: "100px",
                            text: "OK"
                        }]
                    });
                    myDialog.show();
                }
            }
        }

        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'title'
        })
    }

    const toolbarTitleRender = () => {
        return (
            <div>
                <h2>{"Users"}</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="pr-5 d-flex pl-0">
                {chainId != -1 &&
                    <ChainDropDown
                        chains={chainsDataSource}
                        selectedChain={chainId}
                        onChainSelect={onChainSelect}
                    >
                    </ChainDropDown>
                }

                {chainId > -1 && chainId != null &&
                    <StoreDropDown
                        stores={storeDataSource}
                        selectedStore={routeParams.get("storeId") ? Number(routeParams.get("storeId")) : null}
                        selectedChain={chainId}
                        onStoreSelect={onStoreSelect}
                    >
                    </StoreDropDown>
                }
            </div>
            {
                <DataGrid
                    dataSource={dataSource}
                    showBorders={true}
                    ref={userGridRef}
                    onToolbarPreparing={onToolbarPreparing}
                    remoteOperations={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    onRowUpdating={onRowUpdating}
                    height={"620px"}
                >
                    <LoadPanel enabled={true} shading={true} shadingColor="rgba(0,0,0,0.4)" showPane={true}/>
                    <Editing
                        allowAdding={true}
                        allowDeleting={true}
                    >
                    </Editing>
                    <Paging defaultPageSize={10} />
                    {/* <Pager showInfo={true} allowedPageSizes={[5, 10, 20]} 
                            showNavigationButtons={true} 
                            showPageSizeSelector={true}>
                    </Pager> */}
                    <Scrolling mode="infinite" />
                    <FilterRow visible={true} />

                    <Column
                        caption={'ID'}
                        dataField={'id'}
                        allowEditing={false}
                    />
                    <Column
                        dataField={'name'}
                        caption={'Name'}
                    >
                        <RequiredRule />
                    </Column>

                    <Column
                        dataField={'email'}
                        caption={'Email'}
                    >
                        <RequiredRule />
                    </Column>

                    <Column dataField={'storeName'}
                        alignment="left"
                        caption={'Store'}
                        allowEditing={false} />

                    <Column dataField={'chainID'}
                        alignment="left"
                        caption={'Chain'}
                        allowEditing={false}
                        allowFiltering={false}>
                        <Lookup dataSource={chainsDataSource} displayExpr="chainName" valueExpr="id" />
                    </Column>

                    <Column dataField={'isActive'}
                        alignment="left"
                        dataType={'boolean'}
                        caption={'Active'}
                        allowEditing={false} />

                    <Column dataField={'isTestUser'}
                        alignment="left"
                        dataType={'boolean'}
                        caption={'Test User'}
                        allowEditing={false} />

                    {/* overriding existing edit button */}
                    <Column cssClass="dx-command-edit dx-command-edit-with-icons" alignment="right" cellRender={toOpenCellComponent} />
                    <Template name="title" render={toolbarTitleRender} />
                </DataGrid>
            }
            <EditUser hidePopup={onHideUserPopup} />

        </React.Fragment>
    )
};

export default Users;