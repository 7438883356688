import CustomStore from "devextreme/data/custom_store";
import { storeService } from '../../services/store.service';

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

const storeDS = (chainID, toast, setLoading) => {
    return {
        store: new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = '?';
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
                });

                let result = [];
                if (chainID && chainID > 0) {
                    params += `chainId=${chainID}`;
                    result = await storeService.getAllStoresByChain(params);
                    setLoading(false);
                    return {
                        data: result.data,
                        totalCount: result.totalCount,
                        summary: result.summary,
                        groupCount: result.groupCount
                    };
                }
                else {
                    result = await storeService.getAllStores(params);
                    setLoading(false);

                    return {
                        data: result.data,
                        totalCount: result.totalCount,
                        summary: result.summary,
                        groupCount: result.groupCount
                    };
                }
            },
            insert: async (values) => {
                if (Object.keys(values).length > 0) {
                    values.chainID = chainID;
                    values.storeName = values.storeName ? values.storeName.trim() : null;
                    values.storeAlternativeName = values.storeAlternativeName ? values.storeAlternativeName.trim() : null;
                    values.orgNumber = values.orgNumber ? values.orgNumber.trim() : null;
                    values.chainID = chainID;
                    await storeService.addNew(values);
                    toast('Store is created', 'success')
                }
            },
            update: async (key, values) => {
                values.id = key;
                values.chainID = values.chainID == null ? chainID : values.chainID;
                values.storeName = values.storeName ? values.storeName.trim() : null;
                values.storeAlternativeName = values.storeAlternativeName ? values.storeAlternativeName.trim() : null;
                values.orgNumber = values.orgNumber ? values.orgNumber.trim() : null;
                if (key > 0) {
                    await storeService.update(values);
                    toast('Store has been updated', 'success')
                }
            },
            remove: async (key) => {
                if (key > 0) {
                    await storeService.deleteStore(key);
                    toast('Store has been deleted', 'success')
                }
            },
            errorHandler: () => {
                setLoading(false);
            }
        }),
    }
}

export const storeDSRaw = (chainId) => {
    return {
        store: new CustomStore({
            loadMode: 'raw',
            key: 'id',
            load: async () => {
                let params = '?skip=0&take=9999&';
                params += `chainId=${chainId}`;
                let result = await storeService.getAllStoresByChain(params);
                return result?.data;
            },
        }),
    }
}

export default storeDS;