// import * as Constants from '../constants/appConst';
import httpService from "./http.service";
import * as Constants from '../Constants/apiConst';

export const userService = {
    getAllUsers,
    addNew,
    update,
    deleteUser,
    getPosEmployees,
    getUserProfile,
    updateUserProfile,
    getPosEmployee,
    getDefaultUserProfileConfig,
    getAllRoles
};

async function getAllUsers(params) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS;
    path += params;
    return await httpService.get(path);
}

async function addNew(user) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS;
    return await httpService.post(path, user);
}

async function update(user) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS;
    return await httpService.put(path, user);
}

async function deleteUser(id) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS;
    path += `/${id}`
    return await httpService.delete(path);
}

async function getPosEmployees(params) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS + '/posemployees';
    path += params;
    return await httpService.get(path);
}

async function getPosEmployee(chainId, employeeNr) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS + `/posemployees/${employeeNr}`;
    path += `?chainId=${chainId}`;
    return await httpService.get(path);
}

async function getUserProfile(userId, chainId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USER_PROFILE;
    path += `?userId=${userId}&chainId=${chainId}`
    return await httpService.get(path);
}

async function updateUserProfile(userId, userProfileVm, chainId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USER_PROFILE;
    path += `?userId=${userId}&chainId=${chainId}`
    return await httpService.put(path, userProfileVm);
}

async function getDefaultUserProfileConfig() {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS + `/defaultprofileconfig`;
    return await httpService.get(path);
}

async function getAllRoles() {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERS + `/roles`;
    return await httpService.get(path);
}