import {localStorageService} from "./localstorage.service";
import notify from 'devextreme/ui/notify';
import { authHeader } from "../utils/auth-header";

let httpService = class httpService {
    constructor() {
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }

    async get(url, options = {}) {
        options.headers = {...authHeader()};
        options.method = "GET";
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }

    async post(url, body, options = {}) {
        options.headers = {...authHeader()};
        options.method = "POST";
        if (body) {
            options.body = JSON.stringify(body);
        }
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }

    async put(url, body, options = {}) {
        options.headers = {...authHeader()};
        options.method = "PUT";
        if (body) {
            options.body = JSON.stringify(body);
        }
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }

    async delete(url, body, options = {}) {
        options.headers = {...authHeader()};
        options.method = "DELETE";
        if (body) {
            options.body = JSON.stringify(body);
        }
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }

    async handleResponse(response) {
        return response.text().then(text => {
            try {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    if (response.status === 401 || response.status === 403) {
                        localStorageService.clearStorage();
                        window.location.reload(true);
                    }
                    const error = (data && data.message) || response.statusText;
                    this.toast(data.message,'warning');
                    return Promise.reject(error);
                }
                return data;
            }
            catch(e) {
                this.toast("Something went wrong",'error');
                return Promise.reject("Something went wrong");
            }
        });
    }

    toast (message, infoType) {
        notify({
          message: message,
          position: {
            my: 'center top',
            at: 'center top'
          }
        }, infoType, 3000);
      }
}

export default httpService = new httpService();