import React, { useRef } from 'react';
import DataGrid, {
  Column,
    FilterRow,
  Paging,
  Scrolling,
  Selection
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

var dropDownOptions = { width: 400 };

const StoreDropDown = (props) => {
    const dropDownBoxRef = useRef();
    const contentRender = () => {
        return (
            <DataGrid
              dataSource={props.stores}
              remoteOperations={true}
              keyExpr="id"
              height={250}
              selectedRowKeys={[props.selectedStore]}
              hoverStateEnabled={true}
              onSelectionChanged={onSelectionChanged}
              focusedRowEnabled={true}
              // defaultFocusedRowKey={currentValue}
            >
            <FilterRow visible={true} />
              <Column width="30%" dataField="id" alignment="left"/>
              <Column width="70%" dataField="storeName" />
              <Paging enabled={true} pageSize={10} />
              <Scrolling mode="virtual" />
              <Selection mode="single" />
            </DataGrid>
          );
    }

    const onSelectionChanged = (selectionChangedArgs) => {
        if (selectionChangedArgs.selectedRowKeys.length > 0) {
            dropDownBoxRef.current.instance.close();
            props.onStoreSelect(selectionChangedArgs.selectedRowKeys[0]);
        }
    }

    const syncDropDownSelection = (e) => {
      props.onStoreSelect(e.value);
    }

    return (
        <div className="card col-6 dx-fieldset p-2">
            <div className="dx-field">
                <div className="dx-field-label">Stores: </div>
                <div className="dx-field-value">
                    <DropDownBox
                        ref={dropDownBoxRef}
                        dropDownOptions={dropDownOptions}
                        dataSource={props.stores}
                        placeholder={"select a store.."}
                        showClearButton={true}
                        value={props.selectedStore}
                        displayExpr="storeName"
                        onValueChanged={syncDropDownSelection}
                        valueExpr="id"
                        contentRender={contentRender}>
                    </DropDownBox>
                </div>
            </div>
        </div>
    );

}
export default StoreDropDown;
