export const localStorageService = {
    getAuthData,
    setAuthData,
    setRoles,
    clearStorage,
    getRoles
};

function getAuthData() {
    return localStorage.getItem('authData');
}

function setAuthData(authData) {
    return localStorage.setItem('authData', authData);
}

function setRoles(roles) {
    if (roles.length > 0) {
        return localStorage.setItem('roles', roles.toString());
    }
}

function getRoles() {
    let rolesString = localStorage.getItem('roles');
    if (rolesString) {
        return rolesString.split(",");
    } else {
        return [];
    }
}

function clearStorage() {
    localStorage.removeItem('authData');
    localStorage.removeItem('storeName');
    localStorage.removeItem('roles');
}