import notify from "devextreme/ui/notify";

const toast = (message, infoType) => {
    notify({
        message: message,
        position: {
            my: 'center top',
            at: 'center top'
        }
    }, infoType, 3000);
}

export default toast;