import React, { useRef, useState, useEffect, useContext } from 'react';
import DataGrid, {
  // Button,
  Column,
  FilterRow,
  Paging,
  Scrolling,
  Selection
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import DropDownBox from 'devextreme-react/drop-down-box';
import { useHistory } from 'react-router-dom';
import CustomStore from 'devextreme/data/custom_store';
import { supplierService } from '../../services/supplier.service';
import Context from '../../utils/context';
import DataSource from 'devextreme/data/data_source';

var dropDownOptions = { width: 400 };

const SupplierDropDown = (props) => {
  const context = useContext(Context);
  const dropDownBoxRef = useRef();
  const [currentValue, setCurrentValue] = useState(props.selectedSupplierID);
  const [currentSupplierObj, setCurrentSupplierObj] = useState(props.selectedSupplierObj);

  // function to flatten the nested array (recursively)
  function flatten(data, outputArray) {
    data.forEach((element) => {
      if (Array.isArray(element)) {
        flatten(element, outputArray);
      } else {
        outputArray.push(element);
      }
    });
  }

  const isNotEmpty = (value, i) => {
    let flattened = [];
    if (value && value.length > 0) flatten(value, flattened);

    // validating if filter query contains anything which is null
    if (i === "filter" && value !== undefined && value !== null && value !== '' && flattened.findIndex(i => i == null) > -1) return false;
    return value !== undefined && value !== null && value !== '';
  }

  useEffect(() => {
    props.setLoading(true);
      // condition to restrict API call in case there is no change in supplier dropdown value
    if ((props.selectedSupplierObj) && (props.selectedSupplierObj.id === currentValue)) { props.setLoading(false); return };

    if (((context.editUserPopupVisible && context.selectedUserData) || context.createUserPopupVisible) &&
        (props.selectedSupplierID && ((!props.selectedSupplierObj) || (props.selectedSupplierObj.id == props.selectedSupplierID)))) {
        // fetching supplier object for selected supplierID
        supplierService.getSupplierByChainID(props.selectedChainId, props.selectedSupplierID).then(res => {
        setCurrentSupplierObj(res);
        props.onSupplierSelect({ supplierID: null, supplierObj: res });
        props.setLoading(false);
      })
    }
    else {
      setCurrentSupplierObj({})
      props.setLoading(false)
    }
    
  }, [props.selectedSupplierID]);

  const suppliers = () => {
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary'
          ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i], i)) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });

          params += `chainId=${props.selectedChainId}`
          let result = await supplierService.getAllSupplier(params);
          return {
            data: result.data,
            totalCount: result.totalCount,
            summary: result.summary,
            groupCount: result.groupCount
          };
        }
      }),

    })
  }

  const [supplierDS, setSupplierDS] = useState(suppliers());

  const contentRender = () => {

    return (
      <DataGrid
        dataSource={supplierDS}
        remoteOperations={true}
        key="id"
        height={250}
        selectedRowKeys={[currentValue]}
        hoverStateEnabled={true}
        onSelectionChanged={onSelectionChanged}
      >
        <Paging enabled={true} pageSize={10} />
        <Scrolling mode="virtual" />
        <Selection mode="single" />
        <FilterRow visible={true} />
        <Column width="30%" dataField="id" alignment="left" />
        <Column width="70%" dataField="name" />

      </DataGrid>
    );
  }

  const onSelectionChanged = (selectionChangedArgs) => {
    if (selectionChangedArgs.selectedRowsData.length > 0) {
      if (selectionChangedArgs.selectedRowKeys.length > 0) {
        setCurrentValue(selectionChangedArgs.selectedRowKeys[0]);
        setCurrentSupplierObj(selectionChangedArgs.selectedRowsData[0]);
        dropDownBoxRef.current.instance.close();
        props.onSupplierSelect({supplierID: selectionChangedArgs.selectedRowKeys[0], supplierObj: selectionChangedArgs.selectedRowsData[0]});
      }
    }
  }

  const syncDropDownSelection = (e) => {
    setCurrentValue(e.value);
    setCurrentSupplierObj(null);
    props.onSupplierSelect({supplierID: null, supplierObj: null});
  }

  const supplierDisplayExpr = (e) => {
    return (currentSupplierObj && currentSupplierObj.id) ? currentSupplierObj.id + " - " + currentSupplierObj.name : ""
  };

  return (

    <DropDownBox
      ref={dropDownBoxRef}
      dropDownOptions={dropDownOptions}
      placeholder={"select a supplier.."}
      showClearButton={true}
      value={currentSupplierObj}
      displayExpr={supplierDisplayExpr}
      onValueChanged={syncDropDownSelection}
      valueExpr="id"
      contentRender={contentRender}>
    </DropDownBox>

  );

}
export default SupplierDropDown;
