import React, { useState, useEffect, useContext, createRef } from 'react';
import DataGrid, {
    Column,
    Paging,
    Pager,
    FilterRow,
    Editing, 
    Lookup,
    RequiredRule,
    LoadPanel as GridLoadPanel,
    StringLengthRule,
    Scrolling
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import CustomStore from 'devextreme/data/custom_store';
import { storeService } from '../../services/store.service';
import { chainService } from '../../services/chain.service';
import toast from '../../components/toast';
import ChainDropdown from '../../components/dropdowns/chains-dropdown';
import Context from '../../utils/context';
import { useHistory } from 'react-router-dom';
import storeDS from '../../components/data-source/store-data-source';
import { Template } from 'devextreme-react/core/template';
import { custom } from 'devextreme/ui/dialog';

const Stores = (props) => {

    const history = useHistory();
    // let routeParams = useParams();
    let routeParams = new URLSearchParams(props.location.search);
    const context = useContext(Context);
    const [chainId, setChainId] = useState(-1);
    const [dataSource, setDataSource] = useState([]);
    const [posStores, setPosStores] = useState([]);
    const [chainsDataSource, setChainsDataSource] = useState([]);
    const storesGridRef = createRef();
    const [isLoading, setLoading] = useState(true);
    
    useEffect(() => {
        setLoading(true);
        if (routeParams.get("chainId")) {
            setChainId(Number(routeParams.get("chainId")));
            setDataSource(storeDS(routeParams.get("chainId"), toast, setLoading));
            setPosStores(posStoreDS(routeParams.get("chainId")));
        }

        else {
            setChainId(null);
            setDataSource(storeDS(null, toast, setLoading)); // fetching all the stores
            setLoading(false);
        }
        setChainsDataSource(chainsDS());
    }, [routeParams.get("chainId")]);

    useEffect(() => {
        setLoading(true);
        return () => {
            context.setSelectedChainId(null);
        }
    }, [])

    const chainsDS = () => {
        return {
            store: new CustomStore({
                loadMode: 'raw',
                key: 'id',
                load: async () => {
                    let params = '?skip=0&take=9999';
                    let result = await chainService.getAllChains(params);
                    return result?.data;
                },
            }),
        }
    }

    const posStoreDS = (chainID) => {
        return {
            store: new CustomStore({
                loadMode: 'raw',
                key: 'posStoreID',
                load: async () => {
                    let result = await storeService.getAllPosStores(chainID);
                    setLoading(false);
                    return result;
                },
                errorHandler: () => {
                    setLoading(false);
                }
            }),
        }
    }

    const userPageLink = (e) => {
        const dataRow = e.row && e.row.data;
        if (!dataRow.id) {
            return '';
        }
      
        return <a href={`/#/users?chainId=${dataRow.chainID}&storeId=${dataRow.id}`} className="dx-icon dx-icon-group"></a>;
    }

    const onRowUpdating = (e) => {
        for (var property in e.oldData) {
            if (!e.newData.hasOwnProperty(property)) {
                // add a non-existing value  
                e.newData[property] = e.oldData[property];
            }
        }
    }

    const onChainSelect = (e) => {
        if (e)
            history.push({ pathname: `/stores`, search: `?chainId=${e}`})
        else
            history.push({ pathname: `/stores`})
    }

    const onToolbarPreparing = (e) => {
        if (e.toolbarOptions.items && e.toolbarOptions.items.length > 0) {
            if (!chainId || chainId == -1) {
                e.toolbarOptions.items[0].options.onClick = function () {
                    const myDialog = custom({
                        showTitle: false,
                        messageHtml: "<h6>Select chain to add new store</h6>",
                        buttons: [{
                            width: "100px",
                            text: "OK"
                        }]
                    });
                    myDialog.show();
                }
            }
        }
        e.toolbarOptions.items.unshift({
            location: 'before',
            template: 'title'
        })
    }

    const toolbarTitleRender = () => {
        return (
            <div>
                <h2>{"Stores"}</h2>
            </div>
        );
    }

    return (
        <React.Fragment>
                <LoadPanel enabled={true} visible={isLoading} shading={true} shadingColor="rgba(0,0,0,0.4)" showPane={true}/>

        <div className="col-11 d-flex pl-0">
            { chainId != -1 && 
                <ChainDropdown 
                    chains={chainsDataSource} 
                    selectedChain={chainId}
                    onChainSelect={onChainSelect}>
                </ChainDropdown>
            }
        </div>
        
        {
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                loadPanel={false}
                ref={storesGridRef}
                onToolbarPreparing={onToolbarPreparing}
                remoteOperations={true}
                defaultFocusedRowIndex={0}
                height={'643px'}
                columnAutoWidth={true}
                onRowUpdating={onRowUpdating}
            >
                <GridLoadPanel enabled={true}/>
                <Editing 
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}/>
                <Paging defaultPageSize={10} />
                {/* <Pager showInfo={true} allowedPageSizes={[5, 10, 20]} 
                    showNavigationButtons={true} 
                    showPageSizeSelector={true}>
                </Pager> */}
                <Scrolling mode="infinite"/>
                <FilterRow visible={true} />

                <Column dataField={'id'} 
                    caption={'ID'} 
                    width="8%"
                    allowEditing={false}
                />
                <Column
                    dataField={'storeName'}
                    caption={'Name'}
                    width="20%"
                >
                    <RequiredRule/>
                </Column>
                <Column
                    dataField={'storeAlternativeName'}
                    caption={'Alternative name'}
                    width="20%"
                >
                </Column>
                <Column
                    dataField={'orgNumber'}
                    caption={'Org Number'}
                    width="20%"
                >
                    <StringLengthRule message="The field OrgNumber must be a string or array type with a maximum length of '9'" max={9} />
                </Column>
                <Column
                    dataField={'posStoreId'}
                    caption={'POS Store'}
                    width="20%"
                >
                <Lookup dataSource={posStores} displayExpr="storeName" valueExpr="posStoreID" />
                </Column>

                <Column
                    dataField={'isActive'}
                    caption={'Active'}
                    dataType={'boolean'}
                    allowSorting={false}
                    width="10%"
                />
                <Column 
                    cellRender={userPageLink} 
                    caption="Actions" 
                    allowEditing={false} 
                />
                <Template name="title" render={toolbarTitleRender} /> 
            </DataGrid>
        }
    </React.Fragment>
    )
};

export default Stores;