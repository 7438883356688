// import * as Constants from '../constants/appConst';
import httpService from "./http.service";
import * as Constants  from '../Constants/apiConst';

export const userApplicationService = {
    getAll,
    update
};

async function getAll(userId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERAPPLICATION;
    path += `?userId=${userId}`;
    return await httpService.get(path);
}

async function update(userAppMappings) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.USERAPPLICATION;
    return await httpService.put(path, userAppMappings);
}