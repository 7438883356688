import { localStorageService } from '../services/localstorage.service';
import * as Constants from '../Constants/apiConst';

export async function signIn(username, password) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
  };

  return fetch(Constants.apiConst.ENDPOINT + Constants.apiConst.AUTH, requestOptions)
      .then(handleResponse)
      .then((res) => {
          let authdata = window.btoa(username + ':' + password);
          localStorageService.setAuthData(JSON.stringify({username, authdata}));

          return {
            isOk: true,
            data: {username, password}
          };
      }).catch(errorMsg => {
        return {
          isOk: false,
          message: errorMsg
        };
      });
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export function logout() {
  localStorageService.clearStorage();
}

function handleResponse(response) {
  return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              logout();
          }

          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}

export async function getUser() {
  try {
    let user = JSON.parse(localStorageService.getAuthData());
    if (user && user.authdata) {
      return {
        isOk: true,
        data: user
      };
    }
    return {
      isOk: false
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
