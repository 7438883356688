import { CheckBox, DataGrid, DropDownBox, SelectBox } from 'devextreme-react';
import React, { createRef, useContext, useEffect, useState } from 'react'
import Context from '../../../utils/context';
import Form, {
    GroupItem,
    EmailRule,
    Item,
    ButtonItem,
    EmptyItem,
    Label
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import { TextBox, Tooltip } from 'devextreme-react';

const defaultUserConfiguration = {
    configuration: {
        productApi: {
            productNoGeneration: {
                "methodNo": 1,
                "prefix": ""
            }
        }
    }
}

const UserConfiguration = (props) => {
    const context = useContext(Context);
    const [defaultUserProfile, setDefaultUserProfile] = useState(defaultUserConfiguration);
    const productApiMethods = [1, 2];
    const [prefixVisible, setPrefixVisble] = useState(true);
    const [productNoGenerationMethod, setProductNoGenerationMethod] = useState(null);
    const [prefixValue, setPrefixValue] = useState(null);

    useEffect(() => {
        if ((context.editUserPopupVisible && context.selectedUserData) || context.createUserPopupVisible) {
            formatInitialSelection();
        }
    }, [context.editUserPopupVisible, context.createUserPopupVisible])

    // This hook will emit the data to edit-user popup form
    useEffect(() => {

    }, [prefixVisible, prefixValue, productNoGenerationMethod])


    // Initially selecting
    const formatInitialSelection = () => {

        let userConfigurationContext = context.selectedUserData?.userProfile?.configuration;
        if (userConfigurationContext) {
            defaultUserProfile.configuration = userConfigurationContext;
            setDefaultUserProfile(defaultUserProfile);
            setProductNoGenerationMethod(defaultUserProfile.configuration.productApi.productNoGeneration.methodNo);
            setPrefixValue(defaultUserProfile.configuration.productApi.productNoGeneration.prefix);
            prefixHandler(defaultUserProfile.configuration.productApi.productNoGeneration.methodNo);
        } else {
            setProductNoGenerationMethod('');
            setPrefixValue('');
            prefixHandler();
        }
    }

    const prefixHandler = (value) => {
        if (value === productApiMethods[1]) {
            setPrefixVisble(true);
        } else {
            setPrefixVisble(false);
        }
    }

    const productOnValueChanged = (e) => {
        if (e.event) {
            prefixHandler(e.value);

            defaultUserProfile.configuration.productApi.productNoGeneration = {
                ...defaultUserProfile.configuration.productApi.productNoGeneration,
                methodNo: e.value,
                prefix: e.value === productApiMethods[1] ? defaultUserProfile.configuration.productApi.productNoGeneration.prefix : ''
            }
            setProductNoGenerationMethod(e.value);
            props.onUpdate(defaultUserProfile.configuration);
        }
    }

    const handleInputChange = (e) => {
        if (e.event) {
            defaultUserProfile.configuration.productApi.productNoGeneration = {
                ...defaultUserProfile.configuration.productApi.productNoGeneration,
                prefix: e.value,
            }
            props.onUpdate(defaultUserProfile.configuration, true);
            setPrefixValue(e.value);
        }
    }

    const renderContentTooltip = () => {
        return (
            <div>Method No.1 : Use the autoIncrement value of field 'ID' as productNo with prefix 'VR' .<br/>
               Method No.2 : Use the SupplierProduNo as ProductNo, but add a configurable prefix string.</div>
        );
    }


    return (
        <React.Fragment>
            <div id="user-configuration-container">              
                <div className="row ml-3">
                    <h6>Product No Generation</h6>
                    <div id="productNoGeneration" className="align-items-center ml-1 mt-1 dx-icon-info img-info-icon"></div>

                    <Tooltip
                        target="#productNoGeneration"
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        position="bottom"
                        closeOnOutsideClick={false}
                        contentRender={renderContentTooltip}
                    />

                    <div className="col-md-12">
                        <div className="col-12">
                            <Form formData={defaultUserConfiguration.configuration}
                                className="edit-user-container"
                                validationGroup="userData"
                                showValidationSummary={false}>
                                <GroupItem colCount={2}>
                                    <Item itemType="group" colCount={2}>
                                        <Label text="Method" />
                                        <SelectBox items={productApiMethods}
                                            value={productNoGenerationMethod}
                                            onValueChanged={productOnValueChanged} />
                                    </Item>

                                    <Item alignment="right" visible={prefixVisible}>
                                        <Label text="Prefix" />
                                        <TextBox value={prefixValue}
                                            onValueChanged={(e) => { handleInputChange(e) }}>
                                        </TextBox>
                                    </Item>
                                </GroupItem>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserConfiguration;