import httpService from "./http.service";
import * as Constants  from '../Constants/apiConst';

export const storeService = {
    getAllStores,
    getAllStoresByChain,
    getAllPosStores,
    addNew,
    update,
    deleteStore
};

async function getAllStores(params) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.STORES;
    path += params;
    return await httpService.get(path);
}

async function getAllStoresByChain(params) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.STORESBYCHAINID;
    path += params;
    return await httpService.get(path);
}

async function getAllPosStores(chainId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.POSSTORES;
    path += `?chainId=${chainId}`
    return await httpService.get(path);
}

async function addNew(store) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.STORES;
    return await httpService.post(path, store);
}

async function update(store) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.STORES;
    return await httpService.put(path, store);
}

async function deleteStore(storeId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.STORES;
    path += `/${storeId}`
    return await httpService.delete(path);
}
